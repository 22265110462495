import React from "react"
import { Pivot, PivotItem } from "@fluentui/react"
import Header from "../components/header"
import safariBarDark from "../images/safari-bar-dark.png"
import boardVideo from "../media/fourvill-board-2.mp4"
import mediaContent from "../media/fourvill-media-content.mp4"
import boardJpg from "../images/fv-board.jpg"
import mediaJpg from "../images/fv-media.jpg"
import pageJpg from "../images/fv-page.jpg"
import boardMobileJpg from "../images/fv-board-mobile.jpg"
import mediaMobileJpg from "../images/fv-media-mobile.jpg"
import pageMobileJpg from "../images/fv-page-mobile.jpg"
import page from "../media/fourvill-page.mp4"

import "../components/utilities.css"
import "../components/index.css"

const Page = () => {
  return (
    <>
      <div className="h-screen w-screen bg-raisinBlack text-white text-sm overflow-y-auto">
        <Header className=""></Header>
        <section className="py-20 md:pt-28 pt-16 text-center ">
          <h1 className="px-4 md:px-0 mb-8 font-bold text-4xl md:text-5xl lg:text-8xl">
            All-in-one Media and{" "}
            <br className="hidden md:block h-0 leading-none text-xs" />
            Content Workspace
          </h1>
          <p className="text-base md:text-xl">
            Storage, collaboration and publishing tools for Image, Audio, Video
            and Text.
          </p>
        </section>
        <section
          style={{
            minHeight: "40vw",
          }}
          className="max-w-screen-xxl m-auto mb-28 px-2 md:px-8 xl:px-0 rounded-b overflow-hidden"
        >
          <figure className="hidden md:block">
            <img src={safariBarDark} alt="Safari Toolbar" />
          </figure>
          <Pivot
            styles={{
              root: ["flex mb-2 md:mb-0"],
              link: [
                "md:h-6 w-1/2 mx-0 text-sm md:text-xs py-3 md:py-1 text-white border-none leading-snug bg-transparent bg-none focus:bg-transparent active:bg-transparent active:text-white focus:text-white md:focus:bg-raisinBlackDark md:active:bg-raisinBlackDark active:text-white focus:text-white md:bg-raisinBlackDark border-b border-solid border-transparent",
                {
                  // backgroundColor: "#363636",
                  selectors: {
                    "&:hover, &:focus": {
                      background: "#363636",
                      color: "white",
                    },
                  },
                },
              ],
              linkIsSelected: [
                "md:h-6 w-1/2 mx-0 text-sm md:text-xs py-3 md:py-1 text-white leading-snug bg-transparent bg-none focus:bg-transparent active:bg-transparent active:text-white focus:text-white md:focus:bg-raisinBlack md:active:bg-raisinBlack active:text-white focus:text-white md:bg-raisinBlack border-b border-solid border-white md:border-transparent",
                {
                  overflow: "hidden",
                  // backgroundColor: "#252323",
                  selectors: {
                    ":before": {
                      display: "none",
                      content: "'content'",
                      height: 0,
                      width: 0,
                    },
                    "&:hover, &:focus": {
                      // background: "#252323",
                      color: "white",
                    },
                  },
                },
              ],
              text: ["leading-snug"],
            }}
          >
            <PivotItem
              headerText="Board"
              headerButtonProps={{
                "data-order": 1,
                "data-title": "Board",
              }}
            >
              <video
                className="md:block hidden"
                preload="auto"
                loop
                autoPlay
                muted
                src={boardVideo}
                poster={boardJpg}
              ></video>
              <img
                src={boardMobileJpg}
                alt="Fourvill's board screen"
                className="md:hidden block"
              />
            </PivotItem>
            <PivotItem headerText="Media Content">
              <video
                className="md:block hidden"
                preload="auto"
                loop
                autoPlay
                muted
                src={mediaContent}
                poster={mediaJpg}
              ></video>
              <img
                src={mediaMobileJpg}
                alt="Fourvill's media screen"
                className="md:hidden block"
              />
            </PivotItem>
            <PivotItem headerText="Pages">
              <video
                className="md:block hidden"
                preload="auto"
                loop
                autoPlay
                muted
                src={page}
                poster={pageJpg}
              ></video>
              <img
                src={pageMobileJpg}
                alt="Fourvill's page screen"
                className="md:hidden block"
              />
            </PivotItem>
          </Pivot>
        </section>
        <section className="mb-10 pb-12 flex justify-center">
          <a
            href="https://app.fourvill.com"
            className="px-12 py-6 text-sm rounded-full border-2 border-white font-semibold font-inter hover:border-transparent bg-purpleX11 hover:bg-purpleX11 hover:shadow-xl"
          >
            Get started
          </a>
        </section>
        <footer className="px-4 py-12 flex items-center border-t border-solid border-white border-opacity-5">
          <div className="w-280 mx-auto flex justify-center">
            <div>Fourvill Inc. 2022</div>
            <div>
              <ul>
                <li>{/* <a href="/changelog">Changelog</a> */}</li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Page
