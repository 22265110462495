import PropTypes from "prop-types"
import React from "react"
import logo from "../images/fv-logo@2x.png"

// shadow-xl border border-solid border-gray-700 border-opacity-25
const Header = ({ siteTitle }) => (
  <header className="h-24 md:py-4 flex justify-center text-sm relative z-10">
    <div className="h-20 xl:w-280 w-full px-6 xl:px-8 fixed flex items-center justify-between bg-raisinBlack rounded-md">
      <a href="/" className="flex text-base">
        <span className="w-5 mr-2">
          <img src={logo} alt="Fourvill's logo" />
        </span>
        Fourvill
      </a>
      <a
        href="https://app.fourvill.com"
        className="px-4 py-5 text-sm rounded-full border-2 border-white font-semibold font-inter hover:border-transparent hover:bg-purpleX11 bg-raisinBlack hover:shadow-xl"
      >
        Try Beta
      </a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
